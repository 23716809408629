import React from "react";
import CompanyTable from "./components/CompanyTable";
import Title from "antd/es/typography/Title";

const CompanyScene: React.VFC = () => {
    return <div>
        <Title>Companies</Title>
        <CompanyTable/>
    </div>;
}

export default CompanyScene;
