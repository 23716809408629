export default function getOpenMenuKeys(menuMap: StringMap, path: string): string[]|undefined {
    const key = Object.keys(menuMap).find(key => menuMap[key] === path);
    const keyPathParts = key?.split("-");
    const result: string[] = [];
    if (undefined !== keyPathParts?.length && keyPathParts.length > 1) {
        let path = keyPathParts[0];
        result.push(path);
        if (keyPathParts.length > 2) {
            keyPathParts.slice(1, keyPathParts.length - 1).forEach(v => {
                path += ("-" + v);
                result.push(path);
            });
        }
    }
    return result.length ? result : undefined;
}
