import React, {useMemo} from "react";
import useAxios from "axios-hooks";
import {Table, TableProps} from "antd";
import {ColumnsType} from "antd/lib/table";
import Title from "antd/es/typography/Title";
import tld from "../../../../functions/tld";

type ProcessingType = "consuming"|"producing";

type RemoteUniEvent = {
    name: string,
    version: string,
    data: any[],
    dataVersion: string,
    companyId?: string,
}

type UniEvent = RemoteUniEvent;

type RemoteError = {
    id: string,
    created: string,
    message: string,
    processingType: ProcessingType,
    companyId?: string,
    context?: any[],
    uniEvent?: RemoteUniEvent,
    messagingPayload?: string,
    kafkaTopic?: string,
    kafkaPartition?: number,
    kafkaOffset?: number,
};

type OpsError = {
    id: string,
    created: Date,
    message: string,
    processingType: ProcessingType,
    companyId?: string,
    context?: any[],
    uniEvent?: UniEvent,
    messagingPayload?: string,
    kafkaTopic?: string,
    kafkaPartition?: number,
    kafkaOffset?: number,
};

const ErrorTable: React.VFC = () => {

    const [{ data, loading, error }] = useAxios<RemoteError[]>(
        {
            url: tld(process.env.REACT_APP_URL_API_INTEGRATION) + '/v1/ops/errors',
            method: 'GET',
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );

    const opsErrors: OpsError[]|undefined = useMemo(
        () => (loading || !Array.isArray(data)) ? undefined : data.map( remoteError => ({
            id: remoteError.id,
            created: new Date(remoteError.created),
            message: remoteError.message,
            processingType: remoteError.processingType,
            companyId: remoteError.companyId,
            context: remoteError.context,
            uniEvent: remoteError.uniEvent,
            messagingPayload: remoteError.messagingPayload,
            kafkaTopic: remoteError.kafkaTopic,
            kafkaPartition: remoteError.kafkaPartition,
            kafkaOffset: remoteError.kafkaOffset,
        })),
        [data, loading]
    );

    console.log(data, loading, error);

    const columns: ColumnsType<OpsError> = [
        {
            title: "created",
            dataIndex: "created",
            render: v => v.toLocaleString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            }),
            sorter: (a, b) => a.created.getTime() - b.created.getTime(),
            defaultSortOrder: "ascend",
        },
        {
            title: "processingType",
            dataIndex: "processingType",
            sorter: (a, b) => (a.processingType || "").localeCompare(b.processingType || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "companyId",
            dataIndex: "companyId",
            sorter: (a, b) => (a.companyId || "").localeCompare(b.companyId || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "message",
            dataIndex: "message",
            sorter: (a, b) => (a.message || "").localeCompare(b.message || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "uniEvent",
            dataIndex: "uniEvent",
            sorter: (a, b) => (a.uniEvent?.name || "").localeCompare(b.uniEvent?.name || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "messagingPayload",
            dataIndex: "messagingPayload",
            sorter: (a, b) => (a.messagingPayload || "").localeCompare(b.messagingPayload || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "kafkaTopic",
            dataIndex: "kafkaTopic",
            sorter: (a, b) => (a.kafkaTopic || "").localeCompare(b.kafkaTopic || ""),
            defaultSortOrder: "ascend",
        },
        {
            title: "kafkaPartition",
            dataIndex: "kafkaPartition",
            sorter: (a, b) => ( a.kafkaPartition || 0 )  - ( b.kafkaPartition || 0 ),
            defaultSortOrder: "ascend",
        },
        {
            title: "kafkaOffset",
            dataIndex: "kafkaOffset",
            sorter: (a, b) => ( a.kafkaOffset || 0 )  - ( b.kafkaOffset || 0 ),
            defaultSortOrder: "ascend",
        },
    ];

    const tableProps: TableProps<OpsError> = {
        columns,
        rowKey: item => item.id,
        dataSource: opsErrors,
        loading: undefined === opsErrors
    };

    return <div>
        <Title level={2}>app-connect</Title>
        <Table {...tableProps} />
    </div>;
}

export default ErrorTable;
