import React, {useCallback, useState} from "react";
import axios from "axios";
import useAxios from "axios-hooks";

import {Link, Route, useLocation} from "react-router-dom";

import {Alert, Layout, Menu, Spin, Typography} from "antd";
import {DatabaseOutlined, TeamOutlined} from "@ant-design/icons";

import styles from "./App.module.css";
import ErrorsScene from "./scenes/DataProcessing/Errors/ErrorsScene";
import CompanyScene from "./scenes/Customers/Company/CompanyScene";
import ConsoleScene from "./scenes/Console/ConsoleScene";
import getSelectedMenuKeys from "./functions/getSelectedMenuKeys";
import getOpenMenuKeys from "./functions/getOpenMenuKeys";
import DbDumpsScene from "./scenes/DataProcessing/DbDumps/DbDumpsScene";
import tld from "./functions/tld";

const renderWarning = (title: string, message: string): React.ReactNode => {
    return <Alert
        message={title}
        description={message}
        type="warning"
        showIcon
    />;
};

const App = () => {

    const location = useLocation();

    const [{data: authData, loading: authLoading, error: authError}] = useAxios(
        {
            url: tld(process.env.REACT_APP_URL_API_AUTH) + '/v1/logins',
            method: 'GET',
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const onCollapse = useCallback(
        () => setCollapsed(!collapsed),
        [collapsed, setCollapsed]
    );

    const errors: React.ReactNodeArray = [];
    let authentication: {
        status: string,
        companyName: string,
        roles: string[],
        http: {
            authorization: string
        }
    } | null = null;
    if (!authLoading) {
        if (!authError) {
            if (undefined === authData[0] || "active" !== authData[0].status) {
                errors.push(renderWarning("Access denied", "Not logged in."));
            } else {
                authentication = authData[0];
                axios.defaults.headers.common["Authorization"] = authData[0].http.authorization;
            }
        }
    }
    if (authentication && -1 === authentication.roles.indexOf("prv")) {
        errors.push(renderWarning("Access denied", "Insufficient privileges."));
    }
    if (authError) errors.push(renderWarning("Access denied", authError.message));

    const menuMap: StringMap = {
        "1-1": "/companies",
        "2-1-1": "/console-app-connect",
        "2-1-2": "/console-app-timeseries",
        "2-1-3": "/console-app-upload",
        "2-2-1": "/errors",
        "2-3-1": "/anonymized-db-dumps",
    };

    const isOpRole = authentication?.roles.some(role => role.includes('op'));
    const menu = isOpRole ? [
        <Menu.SubMenu key="1" icon={<TeamOutlined/>} title="Customers">
            <Menu.Item key="1-1"><Link to={menuMap["1-1"]}>Companies</Link></Menu.Item>
        </Menu.SubMenu>
    ] : [];
    if (authentication && (-1 !== authentication.roles.indexOf("dev") || -1 !== authentication.roles.indexOf("opDat"))) {
        menu.push(<Menu.SubMenu key="2" icon={<DatabaseOutlined/>} title="Data Processing">
            {isOpRole ? <Menu.ItemGroup key="g2-1" title="Console">
                <Menu.Item key="2-1-1"><Link to={menuMap["2-1-1"]}>app-connect</Link></Menu.Item>
                <Menu.Item key="2-1-2"><Link to={menuMap["2-1-2"]}>app-timeseries</Link></Menu.Item>
                <Menu.Item key="2-1-3"><Link to={menuMap["2-1-3"]}>app-upload</Link></Menu.Item>
            </Menu.ItemGroup> : null}
            <Menu.ItemGroup key="g2-2" title="Logs">
                <Menu.Item key="2-2-1"><Link to={menuMap["2-2-1"]}>Errors</Link></Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup key="g2-3" title="Database dumps">
                <Menu.Item key="2-3-1"><Link to={menuMap["2-3-1"]}>Anonymized tenant data</Link></Menu.Item>
            </Menu.ItemGroup>
        </Menu.SubMenu>);
    }

    return authLoading
        ? <div className={styles.stage}><Spin size="large" tip="Verifying Authentication"/></div>
        : (errors.length
            ? <div className={styles.stage}>{errors}</div>
            : <Layout style={{minHeight: "100vh"}}>
                <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className={styles.logo}><img alt="PlusFix"
                                                      src="https://cdn.plusfix.finance/logo/v1/plusfix.png"/></div>
                    <Menu
                        theme="dark"
                        defaultOpenKeys={getOpenMenuKeys(menuMap, location.pathname) || isOpRole ? ["1"] : ["2"]}
                        defaultSelectedKeys={getSelectedMenuKeys(menuMap, location.pathname) || isOpRole ? ["1-1"] : ["2-2-1"]}
                        mode="inline"
                    >{menu}</Menu>
                </Layout.Sider>
                <Layout>
                    <Layout.Header>
                        <Typography.Title
                            style={{margin: 0, lineHeight: "60px", opacity: "0.2", position: "absolute", right: 50}}>Operations
                            Center</Typography.Title>
                        {/*<Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>*/}
                        {/*    <Menu.Item key="1">nav 1</Menu.Item>*/}
                        {/*    <Menu.Item key="2">nav 2</Menu.Item>*/}
                        {/*    <Menu.Item key="3">nav 3</Menu.Item>*/}
                        {/*</Menu>*/}
                    </Layout.Header>
                    <Layout.Content style={{margin: '0 16px'}}>
                        <div className={styles.stage}>
                            <Route exact path={isOpRole ? ["/", "/companies"] : "/companies"} component={CompanyScene}/>
                            <Route exact path={!isOpRole ? ["/", "/errors"] : "/errors"} component={ErrorsScene}/>
                            <Route exact path="/console-app-connect" component={() => <ConsoleScene
                                appName="app-connect"
                                appProcessesUrl={tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/ops/processes'}
                                companyName={authentication?.companyName || ""}/>}
                            />
                            <Route exact path="/console-app-timeseries" component={() => <ConsoleScene
                                appName="app-timeseries"
                                appProcessesUrl={tld(process.env.REACT_APP_URL_API_TIMESERIES) + '/v1/ops/processes'}
                                companyName={authentication?.companyName || ""}/>}
                            />
                            <Route exact path="/console-app-upload" component={() => <ConsoleScene
                                appName="app-upload"
                                appProcessesUrl={tld(process.env.REACT_APP_URL_API_UPLOAD) + '/v1/ops/processes'}
                                companyName={authentication?.companyName || ""}/>}
                            />
                            <Route exact path="/anonymized-db-dumps" component={() => <DbDumpsScene
                                appName="app-connect"
                                appProcessesUrl={tld(process.env.REACT_APP_URL_API_INTEGRATION) + '/v1/ops/anonymizedDbDumps'}
                            />}
                            />
                        </div>
                    </Layout.Content>
                </Layout>
            </Layout>);
}

export default App;
