import React from "react";
import styles from "./WarningBanner.module.css";
import {WarningOutlined} from "@ant-design/icons";

const WarningBanner: React.FunctionComponent = () => {
    if ("production" === process.env.NODE_ENV) {
        return <div className={`${styles.title} ${styles.production}`}>
            <WarningOutlined className={styles.warning}/>
            <div>{process.env.NODE_ENV}</div>
            <WarningOutlined className={styles.warning}/>
        </div>;
    } else {
        return null;
    }
}

export default WarningBanner;
