import React from "react";
import { Select } from "antd";
const { Option, OptGroup } = Select;

function* readCommandLines(raw: string):  IterableIterator<string> {
    const rawLines = raw.split("\n");

    let headRead = false;
    const headFooter = "Available commands:";
    const commandFooter = "done.";
    for(let i=0; i < rawLines.length; i++)
    {
        const line = rawLines[i].trim();
        if ("" === line) {
            continue;
        }
        if (commandFooter === line) {
            break;
        }
        if (!headRead && headFooter === line) {
            headRead = true;
            continue;
        }
        if (headRead) {
            yield rawLines[i];
        }
    }
}

function trimSplit(value: string): string[] {
    const parts = value.trim().match(/^(\S+)(.*)$/);
    return parts ? [parts[1]?.trim() ?? "", parts[2]?.trim() ?? ""] : ["", ""];
}

export default function commandListToSelectOptions(raw: string): React.ReactNode|undefined {
    const options: { [key: string]: React.ReactNode[]} = {
        "general": [],
    };
    let currentGroup = "general";
    let count = 0;
    let groupCount = 1;
    let groupOptionCount = 0;
    for (let line of readCommandLines(raw)) {
        const isCommand = " " === line[1];
        const isGroup = !isCommand;
        const [title, description] = trimSplit(line);
        if (isGroup) {
            currentGroup = title;
            groupCount++
            groupOptionCount = 0;
        } else {
            if (undefined === options[currentGroup]) {
                options[currentGroup] = [];
            }
            let optionDescription = "" === description ? "" : " - " + description;
            groupOptionCount++;
            let option = <Option
                key={groupCount + "-" + groupOptionCount}
                value={title}
            >{title + optionDescription}</Option>;
            options[currentGroup].push(option);
            count++;
        }
    }
    return count ? <>{Object.keys(options).map((key, i) => <OptGroup key={i + 1} label={key}>{options[key]}</OptGroup>)}</> : undefined;
}
