import React, {useEffect, useState} from "react";
import axios from "axios";
import fetchS from "../../../../modules/fetch-streaming";
import styles from "./CommandInput.module.css";
import { Alert, Button, Input, Select } from "antd";
import useAxios from "axios-hooks";
import commandListToSelectOptions from "./functions/commandListToSelectOptions";

const { TextArea } = Input;

interface CommandInputProps {
    url: string,
    onOut?: (out: string) => void
}

const Page: React.FunctionComponent<CommandInputProps> = ({
    url,
    onOut = (out: string) => console.log(out)
}) => {
    const [command, setCommand] = useState<string>('');
    const [fetching, setFetching] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);
    const [commandSelector, setCommandSelector] = useState<React.ReactNode|undefined>(undefined);

    const [{ data: listData }] = useAxios(
        {
            url,
            method: 'POST',
            withCredentials: true,
            headers: {
                ...axios.defaults.headers.common,
                "Accept": "text/plain",
                "Content-Type": "application/json",
            },
            data: '{"command":"list"}'
        }
    );

    useEffect(() => {
        if (undefined !== listData) {
            setCommandSelector(<div><Select
                showSearch
                placeholder="commands"
                style={{ width: '100%' }}
                onChange={(value: string) => setCommand(value + ' ')}
            >{commandListToSelectOptions(listData)}</Select></div>);
        }
    }, [listData]);

    const runCommand = () => {
        setFetching(true);
        const commandHeader = `> ${command}\n\nrunning… \n\n`;
        onOut(commandHeader);
        const options = {
            url,
            method: 'POST',
            credentials: 'include',
            headers: {
                ...axios.defaults.headers.common,
                "Accept": "text/plain",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ command })
        };
        fetchS(url, options, stream => {
            onOut(stream);
        }).then(() => {
            setFetching(false);
        }, error => {
            setError(error.toString());
            setFetching(false);
            setCommand('');
        });
    };

    const errorEl = error
        ? <Alert
            message="Command failed"
            description={error}
            type="error"
            closable
            banner
            onClose={() => setError(null)}
        />
        : null;

    return (
        <div className={styles.main}>
            {errorEl}
            {commandSelector}
            <div className={styles.input}>
                <TextArea
                    autoSize
                    allowClear
                    style={{width: '100%'}}
                    onChange={(event) => setCommand(event.target.value.replace(/\n/g, ''))}
                    onPressEnter={() => runCommand()}
                    value={command}/>
                <Button
                    style={{height: 'auto'}}
                    loading={fetching}
                    onClick={() => runCommand()}
                >Run</Button>
            </div>
        </div>
    )
};

export default Page;
