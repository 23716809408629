import React, {useState} from "react";
import axios from "axios";
import Title from "antd/es/typography/Title";
import {Button, Input} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

interface PageProps {
    appName: string,
    appProcessesUrl: string,
}

const DbDumpsScene: React.FunctionComponent<PageProps> = ({ appProcessesUrl}) => {

    const [inputText, setInputText] = useState<{ maxNormalizationValue: string, companyId: string }>({
        maxNormalizationValue: '',
        companyId: ''
    })
    const [error, setError] = useState<string>('');

    const inputChange = e => {
        const {value, name} = e.target;
        setInputText({...inputText, [name]: value})
    }
    const downloadDump = () => {
        const {maxNormalizationValue, companyId} = inputText;
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                ...axios.defaults.headers.common,
                "Accept": "text/plain",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({maxNormalizationValue, companyId})
        };
        // @ts-ignore
        fetch(appProcessesUrl, options)
            .then( res => {
                if(res.status !== 200){
                    throw new Error('Request failed with status ' + res.status)
                }
                return res.blob()
            } )
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'anonymizedDbDump.sql';
                a.click();
            }).catch((ex) => {
            setError(ex.message)
            setTimeout(() => {
                setError('')
            }, 3000)
        })
    };

    return <div>
        <Title>Download database dumps</Title>
        <div>
            tenant id: <Input type="text" name="companyId" style={{width: '100%'}} value={inputText.companyId} onChange={inputChange}/>
            maximum amount normalization value:
            <Input type="text"
                   name="maxNormalizationValue"
                   style={{width: '100%'}}
                   value={inputText.maxNormalizationValue}
                   onChange={inputChange}
            />
            <Button
                size="small"
                icon={<DownloadOutlined/>}
                onClick={downloadDump}
                style={{margin: 5}}
            />
            <p style={{color: 'red', fontSize: '16px'}}>{error}</p>
        </div>
    </div>;
}

export default DbDumpsScene;
