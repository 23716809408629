import React, {useCallback, useMemo, useState} from "react";
import axios from "axios";
import useAxios from "axios-hooks";
import {notification, Table, TableProps} from "antd";
import {ColumnsType} from "antd/lib/table";
import DeleteButton from "../../../../components/Button/DeleteButton";
import styles from "./CompanyTable.module.css"
import tld from "../../../../functions/tld";

type RemoteUser = {
    id: string,
    email: string,
    name: string,
    role: string,
};

type RemoteCompany = {
    id: string,
    created: string,
    deleted?: string,
    name: string,
    isMultiUnit?: boolean,
    users?: RemoteUser[],
};

type User = RemoteUser;

type OpsCompany = {
    id: string,
    created: Date,
    deleted?: Date,
    name: string,
    isMultiUnit: boolean,
    users?: User[],
};

const CompanyTable: React.VFC = () => {

    const [{ data, loading }] = useAxios<RemoteCompany[]>(
        {
            url: tld(process.env.REACT_APP_URL_API_AUTH) + '/v1/ops/companies',
            method: 'GET',
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );

    const [deletedCompanyIds, setDeletedCompanyIds] = useState<string[]>([]);

    const companies: OpsCompany[]|undefined = useMemo(
        () => (loading || !Array.isArray(data)) ? undefined : data.map( remoteCompany => ({
            id: remoteCompany.id,
            created: new Date(remoteCompany.created),
            deleted: remoteCompany.deleted ? new Date(remoteCompany.deleted) : (
                deletedCompanyIds.indexOf(remoteCompany.id) > -1 ? new Date() : undefined
            ),
            name: remoteCompany.name,
            isMultiUnit: remoteCompany.isMultiUnit || false,
            users: remoteCompany.users
        })),
        [data, loading, deletedCompanyIds]
    );

    const deleteCompany = useCallback(async (id: string, name: string) => {
        const url = tld(process.env.REACT_APP_URL_API_AUTH) + '/v1/ops/companies/' + id;
        await axios.delete(url, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        notification.info({message: "Company deleted: " + name});
        setDeletedCompanyIds([...deletedCompanyIds, id]);
    }, [deletedCompanyIds]);

    const columns: ColumnsType<OpsCompany> = [
        {
            title: "name",
            dataIndex: "name",
            sorter: (a, b) => (a.name).localeCompare(b.name),
            defaultSortOrder: "ascend",
        },
        {
            title: "created",
            dataIndex: "created",
            render: v => v.toLocaleString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            sorter: (a, b) => a.created.getTime() - b.created.getTime(),
            defaultSortOrder: "ascend",
        },
        {
            title: "deleted",
            dataIndex: "deleted",
            render: v => v?.toLocaleString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            sorter: (a, b) => (a.deleted?.getTime() || 0) - (b.deleted?.getTime() || 0),
            defaultSortOrder: "ascend",
        },
        {
            title: "isMultiUnit",
            dataIndex: "isMultiUnit",
            render: v => v ? "yes" : "",
            sorter: (a, b) => + a.isMultiUnit - + b.isMultiUnit,
            defaultSortOrder: "ascend",
        },
        {
            title: "id",
            dataIndex: "id",
            sorter: (a, b) => (a.id).localeCompare(b.id),
            defaultSortOrder: "ascend",
        },
        {
            title: "userList",
            dataIndex: "users",
            render: (users?: User[]) => {
                return users && <ul>{users.map(user => <li key={user.id + user.role} style={{whiteSpace:"nowrap"}}>{user.name} {"<" + user.email + ">"}</li>)}</ul>;
            },
        },
        {
            title: "actions",
            dataIndex: "id",
            render: (id: string, company) => {
                return company.deleted
                    ? null
                    : <DeleteButton onClick={() => deleteCompany(id, company.name)}>Löschen</DeleteButton>;
            },
        },
    ];

    const tableProps: TableProps<OpsCompany> = {
        columns,
        rowKey: item => item.id,
        dataSource: companies,
        loading: undefined === companies,
        pagination: false,
        size: "small",
        rowClassName: company => company.deleted ? styles.deleted : "",
    };

    return <Table {...tableProps} />;
}

export default CompanyTable;
