import React from "react";
import ErrorTable from "./components/ErrorTable";
import Title from "antd/es/typography/Title";

const ErrorsScene: React.VFC = () => {
    return <div>
        <Title>Processing Errors (all companies)</Title>
        <ErrorTable/>
    </div>;
}

export default ErrorsScene;
