import React, { useRef } from "react";
import {Button, PageHeader, Switch} from "antd";
import CommandInput from "./components/CommandInput";
import styles from "./ConsoleScene.module.css";
import {ArrowDownOutlined, CloseOutlined} from "@ant-design/icons";
import WarningBanner from "./components/WarningBanner/WarningBanner";

interface PageProps {
    appName: string,
    appProcessesUrl: string,
    companyName: string,
}

const ConsoleScene: React.FunctionComponent<PageProps> = ({ appName, appProcessesUrl, companyName = "" }) => {
    const consoleRef = useRef<HTMLDivElement>(null);

    const clearConsole = () => {
        if (null !== consoleRef.current) {
            consoleRef.current.textContent = '';
        }
    };

    let previousEndsWithProgress = false;
    let autScrollEnabled = true;

    return <div className={styles.page}>
        <WarningBanner />
        <PageHeader title={`Console ${appName} - ${companyName}`}/>
        <CommandInput
            url={appProcessesUrl}
            onOut={(out: string) => {
                const trimmedOut = out.trim();
                if (consoleRef.current && '' !== trimmedOut) {
                    if (null === consoleRef.current.textContent) {
                        consoleRef.current.textContent = out;
                    } else {
                        const currentStartsWithProgress = null !== trimmedOut.match(/^\.+(\n|$)/);
                        if (previousEndsWithProgress && currentStartsWithProgress) {
                            const previousIndicatorCount = (consoleRef.current.textContent.match(/\.+\s*$/) || [''])[0].trim().length;
                            const currentIndicatorCount = (trimmedOut.match(/^\.+/g) || [''])[0].length;
                            const aggregatedIndicatorCount = previousIndicatorCount + currentIndicatorCount;
                            if (aggregatedIndicatorCount > 100) {
                                const lineBreakPosition = 100 - previousIndicatorCount;
                                out = out.substr(0, lineBreakPosition) + "\n" + out.substr(lineBreakPosition)
                            }
                            consoleRef.current.textContent = consoleRef.current.textContent.trim() + out;
                        } else {
                            consoleRef.current.textContent += out;
                        }
                    }
                    previousEndsWithProgress = null !== trimmedOut.match(/(\n|^)\.+$/);
                    if (autScrollEnabled) {
                        consoleRef.current.scrollTop = 9999999;
                    }
                }
            }}
        />
        <div style={{display: "flex", position: "absolute", right: 20, alignItems: "center"}}>
            <Switch
                size="small"
                checkedChildren={<ArrowDownOutlined/>}
                unCheckedChildren={<ArrowDownOutlined/>}
                defaultChecked
                onChange={autScroll => {
                    if (autScroll) {
                        autScrollEnabled = true;
                        if (consoleRef.current) {
                            consoleRef.current.scrollTop = 9999999;
                        }
                    } else {
                        autScrollEnabled = false;
                    }
                }}
                style={{margin: 5}}
            />
            <Button
                ghost
                size="small"
                icon={<CloseOutlined/>}
                onClick={() => clearConsole()}
                style={{margin: 5}}
            />
        </div>
        <div className={styles.console} ref={consoleRef}/>
    </div>;
}

export default ConsoleScene;
