import React, {useRef} from "react";
import {Button, ButtonProps, Popconfirm} from "antd";

const DeleteButton: React.FC<ButtonProps> = ({
    hidden,
    children,
    onClick = () => {},
    ...props
}) => {
    const lastClickEvent = useRef<React.MouseEvent<HTMLElement>|undefined>(undefined);

    return hidden ? null : <Popconfirm
        key="delete"
        title={"Proceed with deletion? This can not be undone."}
        okText={"Yes, delete"}
        cancelText={"Cancel"}
        onConfirm={() => onClick(lastClickEvent.current!)}
    ><Button
        {...props}
        danger
        ghost
        onClick={e => lastClickEvent.current = e}
    >{children}</Button></Popconfirm>;
}

export default DeleteButton;
